<template>
    <div class="flex">
        <modal-field label="Carrier" class="mb-3 mr-3 w-1/2">
            <multiselect
                v-model="carrier"
                :options="carriersList"
                class="no-branding"
                label="name"
                track-by="id"
                @input="updateServiceList"
            >
                <template slot="clear">
                    <div
                        v-if="carrier"
                        @click="carrier = null; service = null; serviceList = []"
                        class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                    >
                        <svg-importer icon-name="icons/close" key="close-1" />
                    </div>
                </template>
            </multiselect>
        </modal-field>

        <modal-field label="Service" class="mb-3 ml-3 w-1/2">
            <multiselect
                v-model="service"
                :options="serviceList"
                label="name"
                track-by="id"
                class="no-branding"
                @search-change="searchService"
                @open="searchService"
            >
                <template slot="clear">
                    <div
                        v-if="service"
                        @click="service = null"
                        class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                    >
                        <svg-importer icon-name="icons/close" key="close-2" />
                    </div>
                </template>
            </multiselect>
        </modal-field>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    data: () => {
        return {
            carrier: null,
            carriersList: [],
            service: null,
            serviceList: [],
        };
    },
    methods: {
        async initCarriersList() {
            const { data: { data } } = await axios.get(this.$apiUrl.carriers.base + '?paginate=0');

            this.carriersList = data;
        },
        async initServicesList() {
            this.service = null;

            if (!this.value.carrier_id) {
                return [];
            }

            const { data: { data } } = await axios.get(`${this.$apiUrl.carriers.base}/${this.value.carrier_id}/services/search`);

            this.serviceList = data;
        },
        async updateServiceList() {
            this.service = null;

            if (!this.carrier) {
                return [];
            }

            const { data: { data } } = await axios.get(`${this.$apiUrl.carriers.base}/${this.carrier.id}/services/search`);

            this.serviceList = data;
        },
        async searchService(value) {
            if (this.carrier) {
                return;
            }

            const query = value && value.trim().length ? `name=${value}` : '';

            const { data: { data } } = await axios.get(this.$apiUrl.carrierServices.search + `?limit=10&${query}`);

            this.serviceList = data;
        },
    },
    async created() {
        this.initCarriersList();
    },
}
</script>